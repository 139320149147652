import * as React from "react";
import { Grid } from "react-flexbox-grid";
import Helmet from "react-helmet";

const ServerErrorPage = () => {
  // custom styles
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto 4rem",
  };

  return (
    <>
      <Helmet>
        <title>Server Error | Case Western Reserve University</title>
      </Helmet>
      <Grid>
        <div style={containerStyle}>
          <h1>Server Error has occurred</h1>
          <p>
            The requested resource is either unavailable or you are not
            permitted to access it.
          </p>
          <p>
            This could be a temporary problem. Please try this service again
            shortly.
          </p>
          <p>
            If you believe that you should have access, first check to be
            certain you have the correct URL. If you continue to experience this
            issue, contact the UTech Help Desk at help.case.edu or by phone at
            216.368.HELP(4357).
          </p>
        </div>
      </Grid>
    </>
  );
};

export default ServerErrorPage;
